@charset "utf-8";

* {
  box-sizing: border-box;
}
body {
  font-size: 14px;
}
.v46_58 {
  width: 100%;
  height: 1000px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.v46_96 {
  width: 501px;
  height: 371px;
  background: rgba(255,255,255,1);
  opacity: 1;
  position: absolute;
  top: 628px;
  left: 349px;
  overflow: hidden;
}
.v46_97 {
  width: 240px;
  height: 158px;
  background: url("../images/scroll_down.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 700px;
  left: 500px;
}

.v46_99 {
  width: 67px;
  height: 107px;
  background: rgba(27,26,20,1);
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.v46_100 {
  width: 29px;
  height: 44px;
  background: rgba(27,26,20,1);
  opacity: 1;
  position: absolute;
  top: 244px;
  left: 79px;
}
.v46_101 {
  width: 32px;
  height: 35px;
  background: rgba(27,26,20,1);
  opacity: 1;
  position: absolute;
  top: 254px;
  left: 114px;
}
.v46_102 {
  width: 16px;
  height: 34px;
  background: rgba(27,26,20,1);
  opacity: 1;
  position: absolute;
  top: 253px;
  left: 153px;
}
.v46_103 {
  width: 34px;
  height: 35px;
  background: rgba(27,26,20,1);
  opacity: 1;
  position: absolute;
  top: 254px;
  left: 173px;
}
.v46_104 {
  width: 5px;
  height: 46px;
  background: rgba(27,26,20,1);
  opacity: 1;
  position: absolute;
  top: 242px;
  left: 215px;
}
.v46_105 {
  width: 5px;
  height: 46px;
  background: rgba(27,26,20,1);
  opacity: 1;
  position: absolute;
  top: 242px;
  left: 229px;
}
.v46_106 {
  width: 35px;
  height: 46px;
  background: rgba(27,26,20,1);
  opacity: 1;
  position: absolute;
  top: 242px;
  left: 257px;
}
.v46_107 {
  width: 34px;
  height: 35px;
  background: rgba(27,26,20,1);
  opacity: 1;
  position: absolute;
  top: 254px;
  left: 298px;
}
.v46_108 {
  width: 50px;
  height: 34px;
  background: rgba(27,26,20,1);
  opacity: 1;
  position: absolute;
  top: 254px;
  left: 335px;
}
.v46_109 {
  width: 31px;
  height: 34px;
  background: rgba(27,26,20,1);
  opacity: 1;
  position: absolute;
  top: 254px;
  left: 390px;
}
.v46_64 {
  width: 100%;
  height: 526px;
  background: url("../images/v46_64.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 101px;
  left: 0px;
  overflow: hidden;
}
.v46_65 {
  width: 357px;
  color: rgba(30,30,30,1);
  position: absolute;
  top: 180px;
  left: 820px;
  font-family: Lexend Deca;
  font-weight: 500;
  opacity: 1;
  text-align: left;
  font-size: 25pt;
}
.v46_66 {
  text-align: center;
}
.v46_67 {
  width: 385px;
  height: 255px;
  background: url("../images/v46_67.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 344px;
  left: 463px;
  border: 1px solid rgba(0,0,0,1);
  overflow: hidden;
}
.v46_68 {
  width: 394px;
  height: 219px;
  background: url("../images/v46_68.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 388px;
  left: 112px;
  border: 1px solid rgba(0,0,0,1);
  overflow: hidden;
}
.v46_69 {
  width: 419px;
  height: 233px;
  background: url("../images/v46_69.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 121px;
  left: 363px;
  border: 1px solid rgba(0,0,0,1);
  overflow: hidden;
}
.v46_70 {
  width: 443px;
  height: 242px;
  background: url("../images/v46_70.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 146px;
  left: 20px;
  border: 1px solid rgba(0,0,0,1);
  overflow: hidden;
}
.v46_71 {
  width: 98px;
  height: 200px;
  background: url("../images/v46_71.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 385px;
  left: 864px;
  border: 1px solid rgba(0,0,0,1);
  overflow: hidden;
}
.v46_73 {
  width: 140px;
  color: rgba(255,110,98,1);
  position: absolute;
  top: 40px;
  left: 931px;
  font-family: NanumSquare Neo;
  font-weight: Bold;
  font-size: 30px;
  opacity: 1;
  text-align: center;
}
.v46_74 {
  width: 114px;
  color: rgba(255,110,98,1);
  position: absolute;
  top: 40px;
  left: 1085px;
  font-family: NanumSquare Neo;
  font-weight: Bold;
  font-size: 30px;
  opacity: 1;
  text-align: center;
}
.v46_75 {
  width: 201px;
  height: 97px;
  background: url("../images/v46_75.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
