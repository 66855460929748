@charset "utf-8";

* {
  box-sizing: border-box;
}
body {
  font-size: 14px;
}
.v46_73 {
  width: 140px;
  color: rgba(255,110,98,1);
  position: absolute;
  top: 40px;
  left: 931px;
  font-family: NanumSquare Neo;
  font-weight: Bold;
  font-size: 30px;
  opacity: 1;
  text-align: center;
}
.v46_74 {
  width: 114px;
  color: rgba(255,110,98,1);
  position: absolute;
  top: 40px;
  left: 1085px;
  font-family: NanumSquare Neo;
  font-weight: Bold;
  font-size: 30px;
  opacity: 1;
  text-align: center;
}
.v46_75 {
  width: 201px;
  height: 97px;
  background: url("../images/v46_75.png") no-repeat center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
