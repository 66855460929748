@charset "utf-8";

@import "css/Header.css";
@import "css/Main.css";

.container {
    margin-left: auto;
    margin-right: auto;
    min-width: 1225px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    border: 1px solid #000;
    box-sizing: border-box;
    text-align: center;

}



